import React, { useState } from "react";
import "./Flights.css";
import dubai from "../../../Images/City/dubai.jpg";
import UgAir from "../../../Images/airug.png";

export default function Flights() {
  const [Adult, setAdult] = useState(4);
  const [ChildR, setChildren] = useState(2);
  const [disp, setDisp] = useState(0);

  // const Adjust = () => {
  //   setAdult(Adult++);
  // };
  const FlightCity = [
    {
      City: "Dubai - UAE",
      Price: "1200",
      Image: dubai,
    },
    {
      City: "Adu Dhabi - UAE",
      Price: "1350",
      Image: "https://assets.wam.ae/uploads/2021/11/509152058581628184.jpg",
    },
    {
      City: "Sharjah - UAE",
      Price: "1200",
      Image:
        "https://content.r9cdn.net/rimg/dimg/a8/85/bbf8aa0b-city-9457-164d6a9147e.jpg?width=1200&height=630&crop=true",
    },
  ];

  const Flight = FlightCity.map((Flight, index) => (
    <ul>
      <li align="left">
        <img src={Flight.Image} width="100%" />
      </li>
      <li className="Flightdetails" align="left">
        <h4>{Flight.City}</h4>
        <p> Non - Stop : 5 hrs 40 Min</p>
        <div className="fliPrice" align="right">
          AED {Flight.Price}
        </div>
      </li>
    </ul>
  ));
  return (
    <div onClick={() => setDisp(3)}>
      <div className="TourPage">FLIGHTS</div>
      <br />
      <br />
      <br />
      <div style={{ color: "black" }} className="bb Page" align="center">
        <div className="book">
          <div className="flightSlect" align="left">
            <div className="flightSlect" align=" ">
              <label>ONE WAY</label>
              <label>RETURN</label>
              <label>MULTI WAY</label>
            </div>
            <ul>
              <li>
                <span>From</span>
                <br />
                <input placeholder=" " />
              </li>
              <li>
                <span>To</span>
                <br />
                <input placeholder=" " />
              </li>
              <li>
                <ul>
                  <div className="row" align="center">
                    <div className="col-5">
                      <span>Date</span>
                      <br />
                      <input
                        type="date"
                        id="start"
                        name="trip-start"
                        value="2018-07-22"
                        min="2018-01-01"
                        max="2018-12-31"
                        // style={{ width: "50%" }}
                      />
                    </div>
                    <div className="col">
                      <span>Return</span>
                      <br />
                      <input
                        type="date"
                        id="start"
                        name="trip-start"
                        value="2018-07-22"
                        min="2018-01-01"
                        max="2018-12-31"
                        // style={{ width: "50%" }}
                      />
                    </div>
                  </div>
                </ul>
              </li>
              <li>
                <p>
                  Passenger
                  <br />
                  <span className="passenger">{Adult} Passenger</span> <br />
                </p>
                <span className="dropselect">
                  <svg
                    width="26"
                    height="26"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                    onClick={() => setDisp(2)}
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </span>

                <div
                  className="passengerselect"
                  style={disp === 0      ? { display: "none" } : { display: "block" }}
                >
                  <div style={{}}>
                    <button
                      className="btn btn-success"
                      // onClick={() => setDisp(0)}
                    >
                      Close
                    </button>
                  </div>{" "}
                  <p align="center">
                    <b>
                      Passenger and cabin class
                      <br />
                      You can add up to nine passengers
                    </b>
                  </p>
                  <p className="font-weight-bold">
                    <div className="row">
                      <div className="col">
                        Adult
                        <br /> 12 years and above
                      </div>
                      <div className="col" align="center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          fill="currentColor"
                          class="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setAdult(Adult + 1)}
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                        <span className="passblue">{Adult}</span>{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          fill="currentColor"
                          class="bi bi-dash-circle-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setAdult(Adult - 1)}
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                        </svg>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                        Children
                        <br /> Over 2 and under 12 years
                      </div>
                      <div className="col" align="center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          fill="currentColor"
                          class="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setChildren(ChildR + 1)}
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                        <span className="passblue">{ChildR}</span>{" "}
                        <svg
                          onClick={() => setChildren(ChildR - 1)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          fill="currentColor"
                          class="bi bi-dash-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                        </svg>
                      </div>
                    </div>
                  </p>
                </div>
              </li>
              <li style={{ background: "none " }}>
                <button
                  className="btn btn-danger search"
                  style={{
                    background: "red",
                    marginTop: "-8px",
                    height: "65px",
                  }}
                >
                  SEARCH
                </button>
              </li>
            </ul>
          </div>

          <br />
          <br />
        </div>
        <br />
        <br />
        <div className="fli">
          <div className="row">
            <div className="col" align="left" style={{ paddingLeft: "20px" }}>
              Trips from UAE{" "}
              <span style={{ color: "grey", fontSize: "15px" }}>
                <label className="flightName">Round trip</label>
              </span>
              <br />
            </div>
            <br />
            <br />
            <div
              className="col expofl"
              align="right"
              style={{ fontSize: "16px" }}
            >
              Explore Destinations
            </div>
            <div className="row fligTab">
              <div className="col-5">
                <div>{Flight}</div>
              </div>
              <div
                className="col colAdv"
                style={{ border: "1px solid lightgrey" }}
              >
                <img src={UgAir} width="100%" />
              </div>
            </div>
          </div>
          <br />
          <br />
          <hr />
          <div>
            <label className="DataFlight">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>{" "}
              Location - United Arabs Emirats
            </label>
            <label className="DataFlight">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-cash-stack"
                viewBox="0 0 16 16"
              >
                <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z" />
              </svg>
              Currency - AED
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
