import React, { Component } from "react";
import "./Visa.css";
import World from "./img/world.png";
import Documents from "./img/document.png";
import Pay from "./img/pay.png";
import Recieve from "./img/recieve.png";
import abcd from "../Transfer/abcd.png";
import cbd from "../Transfer/cbd.jpg";
import ai from "../Transfer/airtel.png";
import mtn from "../Transfer/mtn.png";

export class VisaApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgRef: "",
      selectVisa: 3,
      showPay: 0,
      showBanks: 0,
      selected: 7,
    };
    this.selectType = this.selectType.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.showpage = this.showpage.bind(this);
    this.hideepage = this.hideepage.bind(this);
    this.onChangedata = this.onChangedata.bind(this);
  }

  onChangedata = (event) => {
    console.log("Visa");
    this.setState({
      selected: event.target.value,
    });
    console.log("Visa", this.state.selected);
  };
  selectType(Visa) {
    console.log("Visa", Visa);
    this.setState({
      selectVisa: Visa,
    });
  }
  filePreview(e) {
    const file = e.target.files[0];
    this.setState({
      imgRef: URL.createObjectURL(file),
    });
  }
  handleSelectChange = (event) => {
    this.setState({
      selectVisa: event.target.value,
    });
  };
  showpage = () => {
    document.getElementById("pay").innerHTML = "Loading....";

    document.getElementById("notice").style.display = "block";
    document.getElementById("ldd").style.display = "block";

    setTimeout(function () {
      document.getElementById("ldd").style.display = "none";
      document.getElementById("bxx").style.display = "block";
      document.getElementById("pay").innerHTML = "CONTINUE TO PAY";

      // $("#notice").fadeOut();
    }, 2000);
    // this.setState({
    //   showBanks: 1,
    // });
  };
  hideepage = () => {
    // document.getElementById("ldd").style.display = "none";
    document.getElementById("notice").style.display = "none";

    document.getElementById("bxx").style.display = "none";
  };
  render() {
    return (
      <div>
        {" "}
        <div>
          <div className="TourPage">VISA</div>
          <br />
          <div align="center" className="tabheader">
            <h1>APPLY FOR VISA</h1>
          </div>

          <div></div>

          <div className="container">
            <ul className="visaheder">
              <li>
                <img src={World} />
                <br />
                <br />
                Nationality and Type of Visa
              </li>
              <li>
                <img src={Documents} />
                <br />
                <br />
                Upload Photograph
              </li>
              <li>
                <img src={Pay} />
                <br />
                <br />
                Payment
              </li>
              <li>
                <img src={Recieve} />
                <br />
                <br />
                Receive your Visa in 72 hours
              </li>
            </ul>
            <br />
            <div
              align="center"
              className="loadervisa"
              id="notice"
              // style={
              //   this.state.showBanks == 0
              //     ? { display: "none" }
              //     : { display: "block" }
              // }
            >
              <div className="ldd" id="ldd">
                <div class="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
                <p>Loading ....</p>
              </div>
              <div className="bxx" id="bxx">
                <div>
                  <br />
                  <br />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    fill="currentColor"
                    class="bi bi-check2-circle"
                    viewBox="0 0 16 16"
                    style={{ color: "green" }}
                  >
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                  </svg>
                  <br />
                  <br />
                  <ul>
                    <h6>Application Submited Successfully</h6>
                  </ul>
                  Application ID : S24367488595
                  <br />
                  <br />
                  <div>
                    <button className="btn btn-danger" onClick={this.hideepage}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="appBox">
              <div style={{ background: " ", margin: "auto" }}>
                <div
                  style={{ background: "#169597" }}
                  className="visaTypeSelect"
                >
                  {this.state.selectVisa == 0 ? (
                    <h5>SELECT VISA TO APPLY </h5>
                  ) : (
                    ""
                  )}
                  {this.state.selectVisa == 1 ? (
                    <h5>VISA : 30 DAYS | 400 AED (Outside) </h5>
                  ) : (
                    ""
                  )}
                  {this.state.selectVisa == 2 ? (
                    <h5>VISA : 60 DAYS | 830 AED (Outisde) </h5>
                  ) : (
                    ""
                  )}
                  {this.state.selectVisa == 3 ? (
                    <h5>Visit | 60 days | 2200 AED (Inside) </h5>
                  ) : (
                    ""
                  )}
                  {this.state.selectVisa == 4 ? (
                    <h5> Multiple Entry | 60 days | 1800 (Outside)</h5>
                  ) : (
                    ""
                  )}
                  {this.state.selectVisa == 5 ? (
                    <h5>Multiple Entry | 60 days | 2500 (Inside) </h5>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <select name=" " id=" " onChange={this.handleSelectChange}>
                    <option value="0">Visa Type</option>
                    <option value="1">
                      Visit | 30 days | 400 AED (Outside)
                    </option>
                    <option value="2">
                      Visit | 60 days | 830 AED (Outside)
                    </option>
                    <option value="3">
                      Visit | 60 days | 2000 AED (Inside)
                    </option>
                    <option value="4">
                      Multiple Entry | 60 days | 1800 (Outside)
                    </option>
                    <option value="5">
                      Multiple Entry | 60 days | 2500 (Inside)
                    </option>
                  </select>
                </div>
                <h6>
                  Passport Size Phote <span style={{ color: "red" }}>*</span>
                </h6>
                <input
                  type="file"
                  name="photo"
                  onChange={this.filePreview.bind(this)}
                />
                <h6>
                  Name of Applicant <span style={{ color: "red" }}>*</span>
                </h6>
                <input />

                <h6>
                  Nationality <span style={{ color: "red" }}>*</span>
                </h6>
                <input />
                <h6>
                  Email Addresss<span style={{ color: "red" }}>*</span>
                </h6>
                <input />

                <div class="form-group">
                  <h6 for="exampleFormControlFile1">Select Passport Copy</h6>
                </div>
                <input
                  type="file"
                  class="form-control-file"
                  id="exampleFormControlFile1"
                />
                <h6>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </h6>
                <input />
                <h6>Whatsapp Number</h6>
                <input />
                <h6>
                  Select Payment<span style={{ color: "red" }}>*</span>
                </h6>
                <select align="center" onChange={this.onChangedata}>
                  <option value="0">Select Option</option>

                  <option value="1">ADCB</option>
                  <option value="2">CBD</option>
                  <option value="3">AIRTEL MONEY</option>
                  <option value="4">MTN MONEY</option>
                </select>
                <div
                  className="  bNote"
                  style={
                    this.state.selected == 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <img src={abcd} width="130px" />
                  <h4>ADCB</h4>
                  A/C Name :{" "}
                  <span className="bdetail">
                    Speed Express Tours and Travel LLC
                  </span>{" "}
                  <br />
                  IBAN :{" "}
                  <span className="bdetail">AE780030012047909820001</span>{" "}
                  <br />
                  A/C No. : <span className="bdetail">12047909820001</span>
                </div>
                <div
                  className="  bNote"
                  style={
                    this.state.selected == 2
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <img src={cbd} width="130px" />
                  <h4>CBD</h4>
                  Bank Name :{" "}
                  <span className="bdetail">
                    Commercial Bank of Dubai{" "}
                  </span>{" "}
                  <br /> A/C Name:
                  <span className="bdetail">
                    {" "}
                    Speed Express Tours and Travel LLC{" "}
                  </span>
                  <br /> A/C No :<span className="bdetail">
                    {" "}
                    1005010481
                  </span>{" "}
                  <br />
                  IBAN :{" "}
                  <span className="bdetail">
                    {" "}
                    AE280230000001005010481{" "}
                  </span>{" "}
                  <br />
                  Swift Code : <span className="bdetail">CBDUAEAD </span>
                </div>
                <div
                  className="bNote"
                  style={
                    this.state.selected == 3
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <br />
                  <img src={ai} width="130px" />
                  <br />
                  <br />
                  MM Type : <span className="bdetail">AIRTEL MONEY</span> <br />
                  Names : <span className="bdetail">Amuumpaire Bronia</span>
                  <br />
                  A/C No. : <span className="bdetail">0704398796</span>
                </div>
                <div
                  className="  bNote"
                  style={
                    this.state.selected == 4
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <img src={mtn} width="130px" />
                  <br />
                  MM Type : <span className="bdetail">MTN MONEY</span> <br />
                  Names : <span className="bdetail">Amuumpaire Bronia</span>
                  <br />
                  A/C No. : <span className="bdetail">0704398796</span>
                </div>
              </div>
              <h6>
                Proof of Payment<span style={{ color: "red" }}>*</span>
              </h6>
              <input
                type="file"
                name="photo"
                onChange={this.filePreview.bind(this)}
              />
            </div>

            <br />
            <div align="center" className="dd">
              <p>
                <input type="checkbox" /> I agree the information provided is
                correct
              </p>
            </div>

            <div align="center">
              <button
                className="btn btn-danger"
                id="pay"
                onClick={() => this.showpage()}
              >
                CONTINUE TO PAYMENT
              </button>
            </div>
          </div>
          <div>
            <div className="container">
              <div className="important">
                <ul>
                  <h3>IMPORTANT NOTES</h3>
                  <li>
                    All nationalities need to provide a scanned colorful
                    passport copy & photograph, unless otherwise specified.
                  </li>
                  <li>Passport validity should be more than 6 months.</li>
                  <li>
                    All visas provided by Speedexpress Tours and Travels are
                    Electronic Visa(eVisa), once approved it will be send
                    directly via email.
                  </li>
                  <li>
                    Please print out your visa once received through email to
                    present it at the airport.
                  </li>
                  <li>
                    With Speedexpress tours and travels eVisa, you will be able
                    to enter any port, border or airport in the whole country
                    with the carrier of your desire.
                  </li>
                  <li>
                    All visas from SpeedExpress tours and travels have a
                    validity of 60 days from the issuance date(you have 60 days
                    from the visa issued date to enter the UAE) after that the
                    visa will get cancelled automatically.
                  </li>
                  <li>
                    The process of the visa will be proceed after receiving the
                    required documents & payments.
                  </li>
                  <li>
                    For kids 17 years & below, should only be applied with one
                    of the parents & attached the birth certificate of the kid.
                  </li>
                  <li>
                    Nationalities like Afghanistan, Iran, Iraq & Pakistan need
                    to submit the National ID along with the passport copy &
                    photograph.
                  </li>
                  <li>
                    All visas issued from SpeedExpress travels are extendable
                    without leaving the country, kindly contact us a week before
                    the visa expiry.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VisaApply;
