export const Hotels = [
  {
    id: 0,
    name: "Coral Dubai Deira",
    location: "Deira Hotel",
    Price: "130",
    Description:
      "In the heart of Dubai’s central Deira district, this luxurious hotel offers free Wi-Fi in all areas, comfortable modern style rooms and a rooftop swimming pool. Guests have access to on-site fitness centre and sauna",
    images1:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/215575732.jpg?k=b28d72cc7a55c9996111d53a58d8c6016a5f50aae6314e97087cf7ce8160a3d8&o=&hp=1",
    images2:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/215576206.jpg?k=1653ba93a9a66319cd6c3af4fba00aaa954d4cb635b2a10b2493291981db2b61&o=&hp=1",
    images3:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/50556898.jpg?k=1398070c682b777a910df03cfc08a4c41c1913777d51e4abaa858071b0fe3c6c&o=&hp=1",
    images4:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/81246299.jpg?k=579d036b57b8bffe98ac5cef9547f745d367d10f5b819f7174cacee418ef9076&o=&hp=1",
  },
  {
    id: 1,
    name: "Coral Dubai Deira",
    location: "Deira Hotel",
    Price: "130",
    Description:
      "In the heart of Dubai’s central Deira district, this luxurious hotel offers free Wi-Fi in all areas, comfortable modern style rooms and a rooftop swimming pool. Guests have access to on-site fitness centre and sauna",
    images1:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/215575732.jpg?k=b28d72cc7a55c9996111d53a58d8c6016a5f50aae6314e97087cf7ce8160a3d8&o=&hp=1",
    images2:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/215576206.jpg?k=1653ba93a9a66319cd6c3af4fba00aaa954d4cb635b2a10b2493291981db2b61&o=&hp=1",
    images3:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/50556898.jpg?k=1398070c682b777a910df03cfc08a4c41c1913777d51e4abaa858071b0fe3c6c&o=&hp=1",
    images4:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/81246299.jpg?k=579d036b57b8bffe98ac5cef9547f745d367d10f5b819f7174cacee418ef9076&o=&hp=1",
  },
  {
    id: 2,
    name: "Asiana Hotel",
    location: "Al salah Albin, Deira Dubai",
    Price: "150",
    Description:
      "Situated adjacent to the Reef Mall, this elegant 5-star property features modern accommodation in the heart of Deira.<br/> It offers an outdoor swimming pool, a spa and a health club.The rooms feature earthy hues and luxury fibres. Each is air conditioned and equipped with a flat-screen TV and minibar stocked with cool drinks. The modern bathroom offers a separate toilet area and walk-in shower",
    images1:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/289597353.jpg?k=3c354960f68a336e2a00ea30df647ab3de307c3ed5613149202805fdfee91e33&o=&hp=1",
    images2:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/96617129.jpg?k=8efe6cf5068bef1c495dd0ed1d8990fdad13c2ec6b3de12e47edde3389913fc9&o=&hp=1",
    images3:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/289597754.jpg?k=cab4c0a57f1ea404f1cdb151f27f7db81bc98c2e3a9f1ba225f495772fde869b&o=&hp=1",
    images4:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/289597668.jpg?k=3587c7bb917f903bd6afcbffbe79c2e0251f94174d08295687c94d155c9da6bc&o=&hp=1",
  },
  {
    id: 3,
    name: "Country Gardens",
    location: "kitintale, Kampala Ug",
    Price: "130",
    Description:
      "In the heart of Dubai’s central Deira district, this luxurious hotel offers free Wi-Fi in all areas, comfortable modern style rooms and a rooftop swimming pool. Guests have access to on-site fitness centre and sauna",
    images1:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/289597668.jpg?k=3587c7bb917f903bd6afcbffbe79c2e0251f94174d08295687c94d155c9da6bc&o=&hp=1",
    images2: " ",
    images3: " ",
    images4:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/289597668.jpg?k=3587c7bb917f903bd6afcbffbe79c2e0251f94174d08295687c94d155c9da6bc&o=&hp=1",
  },
  {
    id: 4,
    name: "Country Gardens",
    location: "kitintale, Kampala Ug",
    Price: "130",
    Description:
      "In the heart of Dubai’s central Deira district, this luxurious hotel offers free Wi-Fi in all areas, comfortable modern style rooms and a rooftop swimming pool. Guests have access to on-site fitness centre and sauna",
    images1:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/289597668.jpg?k=3587c7bb917f903bd6afcbffbe79c2e0251f94174d08295687c94d155c9da6bc&o=&hp=1",
    images2: " ",
    images3: " ",
    images4:
      "https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/289597668.jpg?k=3587c7bb917f903bd6afcbffbe79c2e0251f94174d08295687c94d155c9da6bc&o=&hp=1",
  },
];
