import React, { Component } from "react";

import { Link } from "react-router-dom";
import "./Hotels.css";
import Services from "../../Services/Api";
import { Hotels as Dhotel } from "../../Data/Data";

export class Hotels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HotelList: [],
      price: false,
      Select: "Select",
    };
  }
  componentDidMount() {
    setInterval(() => {
      Services.getHotels().then((res) => {
        console.log(res.data);
        this.setState({ HotelList: res.data });
      });
    }, 1000);
  }
  render() {
    return (
      <div>
        <div>
          <div>
            <div className="row">
              <div className="col" align="left">
                <span className="heading" style={{ color: "black" }}>
                  Recommended<span className="red"> Hotels</span>
                  <label className="redDivider"></label>
                  <br />
                </span>
              </div>
              <div className="col">
                <div align="right" className="viewAll">
                  View All
                  <svg
                    className="viewAllIco"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <br />

            <div className="HtelTabs">
              {this.state.HotelList.map((ht, index) => (
                <div className="hotl">
                  <div className="row">
                    <div className="col httg" >
                      <img src={Dhotel[ht.id].images1}   />
                    </div>
                    <div className="col htpr" align="center">
                      <p style={{ fontSize: "10px" }}>{ht.HN}</p>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          fill="currentColor"
                          className="bi bi-geo-alt loc"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                        {ht.HL}

                        <p style={{ fontWeight: "bold", fontSize: "10px" }}>
                          Price : {ht.HP} AED
                        </p>
                        <Link to={"onehotel?" + ht.id}>
                          <buttun
                            className="btn btn-warning"
                            style={{ fontSize: "12px" }}
                          >
                            BOOK NOW
                          </buttun>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hotels;
