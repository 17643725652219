import React from "react";
const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.0635969804266!2d55.30464391531502!3d25.26844598386308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4349c446c127%3A0xba9b2e1b797b9b74!2sDeira%20Tower%20-%204th%20Floor%20Al%20Shams%20General%20Trading%20LLC%20-%20D89%20-%20Deira%20-%20Al%20Rigga%20-%20Dubai!5e0!3m2!1sen!2sae!4v1650613593390!5m2!1sen!2sae"
        width="100%"
        height="200"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  );
};
export { AddressMap };
