import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
import { PostData } from "./PostData";
// import "./Login.css";
class LoginEx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      check_textInputChange: false,
      secureTextEntry: true,
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.Login = this.Login.bind(this);
  }

  Login = () => {
    var Email = this.state.email;
    var Password = this.state.password;

    if (Email.length == 0 || Password.length == 0) {
      alert("Required Field Is Missing!!!");
    } else {
      var APIURL = "http://localhost/New/Login.php";

      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      var Data = {
        Email: Email,
        Password: Password,
      };

      fetch(APIURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((Response) => Response.json())
        .then((Response) => {
          alert(Response[0].Message);
          if (Response[0].Message == "Success") {
            console.log("true");
            // this.props.navigation.navigate("HomeScreen");
          }
          console.log(Data);
        })
        .catch((error) => {
          console.error("ERROR FOUND" + error);
        });
    }
  };

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  render() {
    if (this.state.redirectToReferrer || sessionStorage.getItem("userData")) {
      return " ";
    }

    return (
      <div className="row" id="Body">
        <div className="medium-5 columns left">
          <h4>Login</h4>
          <label>Username</label>
          <input type="text" name="email" onChange={this.onChangeEmail} />
          <label>Password</label>
          <input
            type="password"
            name="password"
            onChange={this.onChangePassword}
          />
          <input type="submit" value="Login" onClick={this.Login} />
          <a href="/signup">Registration</a>
        </div>
      </div>
    );
  }
}
export default LoginEx;
