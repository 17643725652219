import React, { Component } from "react";
import HorizontalScroll from "react-scroll-horizontal";

class ScrollingHorizontally extends Component {
  render() {
    const child = { width: `30em`, height: `100px`, border: `1px solid red` };
    const parent = { width: `60em`, height: `100px`, border: `1px solid grey` };
    return (
      <div style={parent}>
        <HorizontalScroll>
          <div style={child} />
          <div style={child} />
          <div style={child} />
        </HorizontalScroll>
      </div>
    );
  }
}

export default ScrollingHorizontally;
