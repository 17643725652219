import React, { useEffect } from "react";
import "./One.css";
import { useState } from "react";
import { Hotels } from "../../../Data/Data";
import Services from "../../../Services/Api";
import { Link } from "react-router-dom";
let Array = [];

export default function SingleHotel() {
  const [page, setPage] = useState(0);
  const [vpage, vsetPage] = useState(1);
  const [night, setNight] = useState(1);
  const [book, getBook] = useState([]);
  const [thisbook, getthisBook] = useState(false);
  const [dateC, setDate] = useState(false);

  const [Hb, setHb] = useState();

  useEffect(() => {
    let id = window.location.href.split("?")[1];
    setPage(id);
    // console.log(Hotels[page].name);
  });

  const onchageDate = (e) => {
    setDate(e.target.value);
    // console.log("vvvv", dateC);
  };

  const BookNome = () => {
    if (localStorage.getItem("Login")) {
      console.log("crrrr");
      const obj = {
        HotelId: page,
        Hotelname: Hotels[page].name,
        Username: localStorage.getItem("Login"),
        Hotelprice: Hotels[page].Price * night,
        Adults: 2,
        Checkin: dateC,
      };

      Services.bookhotel(obj).then((res) => {
        localStorage.setItem("Book", page);
      });
      localStorage.getItem("Book");
      window.location.reload();
    } else {
      alert("PLEASE SIGN IN OR REGISTER");
    }
  };

  const getBooking = () => {
    Services.getBookings().then((res) => {
      // console.log("res.datav", res.data);
      getBook(res.data);
      for (let a = 0; a < res.data.length; a++) {
        if (localStorage.getItem("Login") === res.data[a].Username) {
          if (res.data[a].HotelId == page) {
            // window.alert("Avaliable");
            getthisBook(true);
            // console.log(thisbook);
          }
        }
      }
      // console.log("book", book);
    });
  };
  getBooking();

  // const listItems = Hotels.map((number) => <li> fggggggg</li>);
  return (
    <div>
      <div>
        <br/>
        <div
          className="fixedbook"
          style={{ background: "brown", color: "white" }}
        >
          {/* {listItems} */}
          <div className=" " style={{}}>
            <div className="row bookHtData" style={{ margin: "auto" }}>
              <div className="col">
                <br />
                <h2 style={{ fontSize: "15px" }}>{Hotels[page].name}</h2>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-geo-alt"
                    viewBox="0 0 16 16"
                    style={{ color: "white" }}
                  >
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                  {Hotels[page].location}
                </p>
              </div>
              <div className="col sht ">
                Select Date :{" "}
                <input
                  type="date"
                  name="Select Date"
                  style={{ boxShadow: "0px" }}
                  placeholder="Select Date"
                  onChange={onchageDate}
                />
                <label
                  className="floatplus"
                  onClick={() => setNight(night + 1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-square"
                    viewBox="0 0 16 16"
                    // style={{ color: "white" }}
                  >
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </label>
                <select>
                  <option name=""> {night} Night</option>
                  <option name="">2 Nights</option>
                </select>
                <select>
                  <option name="">1 Adult</option>
                  <option name="">2 Adults</option>
                </select>
              </div>
              <div className="col-3 fff" align="right">
                <div className="pBook">
                  <h6 style={{ fontSize: "10px" }}>
                    PRICE :{" "}
                    <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                      {Hotels[page].Price * night}{" "}
                    </span>{" "}
                    AED
                  </h6>

                  {thisbook ? (
                    <div style={{ fontSize: "10px" }}>
                      <span>You have Booked</span>
                      <br />
                      <Link to="/profile" style={{ color: "lightblue" }}>
                        <span>Manage Bookings</span>
                      </Link>
                    </div>
                  ) : (
                    <span>
                      <button className="btn btn-success" onClick={BookNome}>
                        PROCEED TO BOOK
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Page">
          <div className="_onehotel">
            <div className="_onehotelPic">
              <div className="nmHotel">
                <div align="center">
                  <img className="" src={Hotels[page].images1} width="100%" />
                </div>
                <div align="center">
                  <img className=" " src={Hotels[page].images2} height="70vh" />
                  <img className=" " src={Hotels[page].images2} height="70vh" />

                  <img className=" " src={Hotels[page].images3} height="70vh" />
                </div>
              </div>
              <div className="oneHtimg11">
                <div>
                  <img className="mainOneImg" src={Hotels[page].images1} />
                </div>
                <div>
                  <br />
                  <br />
                  <br />
                  <img className="mainOneImg2" src={Hotels[page].images2} />
                  <br />{" "}
                  <img className="mainOneImg2" src={Hotels[page].images3} />
                </div>
              </div>
            </div>
            <div className="_onehotelPic">
              <div
                className="condiv"
                style={{ background: "brown", color: "white" }}
              >
                <h4 style={{ fontSize: "13px" }}> {Hotels[page].name}</h4>
                ⭐⭐⭐⭐⭐
                <br />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-geo-alt"
                  viewBox="0 0 16 16"
                  style={{ color: "white" }}
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                {Hotels[page].location}
                <div>
                  AED {Hotels[page].Price} / night <br />
                  <h6>2 Adults</h6>
                  4.6(96) <br />
                  Speedexpresstravel.com
                </div>
                <hr />
                <button
                  className="btn btn-danger"
                  style={{ width: "100%", borderRadius: "20px" }}
                >
                  Send Inquiry
                </button>
              </div>

              {/* <div className="condiv">
                <h4>Need help booking?</h4>
                <hr /> Call our customer services team on the number below to
                speak to one of our advisers who will help you with all of your
                holiday needs. <hr /> +971 565843743
              </div> */}
            </div>
          </div>
          <div>
            <ul className="NavHote">
              <li
                onClick={() => vsetPage(0)}
                style={
                  vpage == "0"
                    ? { background: "lightblue" }
                    : { background: "none" }
                }
              >
                Description
              </li>
              <li
                onClick={() => vsetPage(1)}
                style={
                  vpage == 1
                    ? { background: "lightblue" }
                    : { background: "none" }
                }
              >
                Location
              </li>
            </ul>
            <div className=" " style={{ display: "block" }}>
              {vpage == "0" ? (
                <div className="oneHtPage" style={{ width: "100%" }}>
                  <h4>
                    Will be Updated
                  </h4>
                </div>
              ) : (
                ""
              )}
              {vpage == 1 ? (
                <div className=" oneHtPage " style={{ width: "100%" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.0248857171932!2d55.32288361493521!3d25.26974828386252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cc880d83753%3A0x6b1f5d2f9dbeffff!2sAsiana%20Hotel%20Dubai!5e0!3m2!1sen!2sae!4v1654861011261!5m2!1sen!2sae"
                    style={{ border: 0, width: "100%", height: "100%" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              ) : (
                ""
              )}
              {vpage == 2 ? (
                <div className="   oneHtPage" style={{ width: "100%" }}>
                  <h3>Acacia Marina</h3> Courage of our questions cosmic ocean
                  hearts of the stars something incredible is waiting to be
                  known extraplanetary venture? Citizens of distant epochs a
                  very small stage in a vast cosmic arena Rig Veda
                </div>
              ) : (
                ""
              )}
              {vpage == 3 ? (
                <div className=" oneHtPage " style={{ width: "100%" }}>
                  <h3>Acacia Marina</h3> Courage of our questions cosmic ocean
                  hearts of the stars something incredible is waiting
                </div>
              ) : (
                ""
              )}
              {vpage == 4 ? (
                <div className="oneHtPage " style={{ width: "100%" }}>
                  <h3>Acacia Marina</h3> Courage of our questions cosmic ocean
                  hearts of the stars something incredible is waiting to be
                  known extraplanetary venture? Citizens of distant epochs a
                  very small stage in a vast cosmic arena Rig Veda muse about
                  stirred by starlight dispassionate extraterrestrial observer.
                  Stirred by starlight star stuff harvesting star light from
                  which we spring invent the universe tendrils of gossamer
                  clouds encyclopaedia galactica? Orion's sword Sea of
                  Tranquility vanquish the impossible the carbon in our apple
                  pies hundreds of thousands invent the universe? Stirred by
                  starlight another world the ash of stellar alchemy rings of
                  Uranus
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
