import React from "react";
import "./Subscribe.css";

export default function Subscribe() {
  return (
    <div className="sub">
      <div className="Page">
        <div className="ul">
          <ul>
            <li align="center">
              <h2>Stay up to Date</h2>
              <p>Subscribe now and receive the latest travel news.</p>
            </li>
            <li align="center">
              <input placeholder="Your Name" />
              <input placeholder="Email Address" />

              <button className="btn btn-primary">SUBSCRIBE NOW</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
