import React, { Component } from "react";
import "./Header.css";
import { Link } from "react-router-dom";

export class Header extends Component {
  render() {
    return (
      <div className="Header">
        <div className="Main">
          <div className="Page">
            <div className="MainBanner" align=" ">
              <div className="xData">
                <label>
                  Book And Plan Your
                  <br /> VACATION, VISA, TOURS AND TICKETS
                </label>
                <br />
                <br />

                <h3>DREAM | TRAVEL | EXPLORE</h3>
              </div>
            </div>
            <div className="ServiceSelect">
              <div className="sDashboard">
                <ul>
                  <li className="activeTab">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-person-rolodex"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                    </svg>
                    <span style={{ fontWeight: "bold" }}>VISA</span>
                  </li>
                  <li>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-calendar3-range-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 0a2 2 0 0 0-2 2h16a2 2 0 0 0-2-2H2zM0 8V3h16v2h-6a1 1 0 1 0 0 2h6v7a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-4h6a1 1 0 1 0 0-2H0z"
                      />
                    </svg>
                    <Link to="/Flights" style={{ textDecoration: "none" }}>
                      <span style={{ fontWeight: "bold" }}>FLIGHTS</span>
                    </Link>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-hospital"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1h1ZM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5Zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9h-.5Zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5ZM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5Z" />
                      <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1V1Zm2 14h2v-3H7v3Zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm0-14H6v1h4V1Zm2 7v7h3V8h-3Zm-8 7V8H1v7h3Z" />
                    </svg>
                    <Link to="/Hotels" style={{ textDecoration: "none" }}>
                      <span style={{ fontWeight: "bold" }}>HOTELS</span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="selectDash">
                <div className="selectData">
                  <ul>
                    <li>
                      <span>COUNTRY</span>
                      <br />
                      <select>
                        <option>Uganda</option>
                        <option>Uganda</option>
                        <option>Uganda</option>
                      </select>
                    </li>
                    <li>
                      <span>NATIONALITY</span>
                      <br />
                      <select>
                        <option>Uganda</option>
                        <option>Uganda</option>
                        <option>Uganda</option>
                      </select>
                    </li>
                    <li>
                      <span>LOCATION</span>
                      <br />
                      <select>
                        <option>Uganda</option>
                        <option>Uganda</option>
                        <option>Uganda</option>
                      </select>
                    </li>
                    <li>
                      <span>ADVANCED SEACRH</span>
                      <br />
                      <button
                        className="btn btn-danger search"
                        style={{ background: "red" }}
                      >
                        SEARCH
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
