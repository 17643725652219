import "./Apply.css";
import React, { Component } from "react";
import axios from "axios";

export class Apply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      button: "Send Message",
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onSubjectChange = this.onSubjectChange.bind(this);
    this.onMsgChange = this.onMsgChange.bind(this);
    this.submitEmail = this.submitEmail.bind(this);

    // submitEmail;
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
    console.log(this.state.name);
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  onMsgChange(event) {
    this.setState({ message: event.target.value });
  }

  submitEmail(e) {
    e.preventDefault();
    this.setState({ button: "Sending.." });
    axios({
      method: "POST",
      // url: "http://localhost:8080/send",
      url: "http://www.speedexpresstravel.com/mail/mail.php",
      data: this.state,
    }).then((response) => {
      if (response.data.status === "success") {
        alert("Message Sent.");
        this.resetForm();
      } else if (response.data.status === "fail") {
        alert("Message failed to send.");
      }
      this.setState({ button: "Send Message" });
    });
  }

  resetForm() {
    this.setState({ name: "", email: "", subject: "", message: "" });
  }
  render() {
    return (
      <div className="visApp">
        <div className="visabanner" style={{ background: " " }}>
          <h1>CONTACT US</h1>
        </div>
        <div className="container">
          <div className="applydata">
            <div className="contactForm" align="center">
              <div className="form1">
                <h3>Need To Book With Us</h3>
                <span
                  style={{
                    color: "#169597",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Are you planning to stay to UAE soon?
                </span>
                <br /> <br />
                <span
                  style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}
                >
                  {" "}
                  Are you looking for a discount on Visa or Ticket Or Tour
                  Guide?{" "}
                </span>
                <br />
                <br />
                We are here to assist you get to Dubai with ease by providing
                low rate flight ticket, visa, and tours. Gain up to 30% discount
                and get the advantages to spend less with your trip. Don’t waste
                your time looking around, we guarantee the best price in the
                market. Just write us and one of our representative will get
                back to you.
                <br />
                <br />
                <br />
                <h2 style={{ color: "red" }}>
                  For reservations or inquiries, please contact the following
                  numbers:
                </h2>
                <br />
                <div>
                  <div className="contactList">
                    <h6>
                      <label className="font-weight-bold">Kampala :</label>
                      <br /> + 256 704 955 287{" "}
                    </h6>{" "}
                    <h6>
                      <label className="font-weight-bold">Dubai :</label>
                      <br />+ 971 42 556 058
                    </h6>{" "}
                    <h6>
                      <label className="font-weight-bold">Abu Dhabi : </label>
                      <br />+ 971 24 442 564{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="form1">
                <div className="Contact_box">
                  <form onSubmit={this.submitEmail}>
                    <h5>Name</h5>
                    <input
                      placeholder="Your Name"
                      onChange={this.onNameChange}
                      value={this.state.name}
                    />
                    <h5>Email</h5>
                    <input
                      placeholder="Your Email Address"
                      onChange={this.onEmailChange}
                      value={this.state.email}
                    />
                    <h5>Telephone Number</h5>
                    <input
                      placeholder="Your Telephone Address"
                      onChange={this.onMsgChange}
                      value={this.state.message}
                    />
                    <h5>Inquries</h5>
                    <select
                      style={{ width: "80%", height: "40px" }}
                      onChange={this.onSubjectChange}
                    >
                      <option value={0}>Select</option>
                      <option>Visa</option>
                      <option>Ticket</option>
                      <option>Tours</option>
                      <option>Tour Packages</option>
                      <option>Others</option>
                    </select>
                    <br />
                    <br />
                    <input
                      type="submit"
                      value={this.state.button}
                      // onClick={this.submitEmail}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        <div className="divider"></div>
      </div>
    );
  }
}

export default Apply;
