import React from "react";
import Im1 from "../../Images/Visa/1.png";
import im2 from "../../Images/Visa/2.png";
import im3 from "../../Images/Visa/3.png";
import im4 from "../../Images/Visa/4.png";
import im5 from "../../Images/Visa/5.png";
import im6 from "../../Images/Visa/6.png";
import HorizontalScroll from "react-scroll-horizontal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import "./Visa.css";
import { Link } from "react-router-dom";
export default function Visa() {
  const Visa = [
    {
      Visa: "Tourist",
      Price: "400 AED",
      Image: Im1,
    },
    {
      Visa: "Tourist",
      Price: "1200 AED",
      Image: im2,
    },
    {
      Visa: "Tourist",
      Price: "800 AED",
      Image: im3,
    },
    {
      Visa: "Tourist",
      Price: "2000 AED",
      Image: im4,
    },
    {
      Visa: "Multiple Entry",
      Price: "1800 AED",
      Image: im5,
    },
    {
      Visa: "Multiple Entry",
      Price: "2500 AED",
      Image: im6,
    },
  ];
  

  const ViewVsia = Visa.map((visa, index) => (
    <div className="visabox" align="center" data-aos="zoom-in-right">
      <img src={visa.Image} />
      <br />

      <div align="center">
        
        <Link to="/Contact">
          <button className="btn btn-warning visabtn">View Visa</button>
        </Link>
      </div>
    </div>
  ));
  return (
    <div>
      <div>
        <div align="center">
          <div className="row">
            <div className="col" align="left">
              <span className="heading" style={{ color: "black" }}>
                UAE<span className="red"> Visa</span>
                <label className="redDivider"></label>
               </span>
            </div>
            <div className="col">
              <div align="right" className="viewAll">
                View All
                <svg
                  className="viewAllIco"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-arrow-right-short"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="visa visaTab" id="style-1">
          {ViewVsia}
        </div>
        <br />
        
      </div>
    </div>
  );
}
