import React from "react";
import "./Footer.css";
import logo from "../../Images/Speed//Banner.svg";

export default function Footer() {
  return (
    <div>
      <div className="container">
        <footer class="footer-distributed">
          <div class="footer-left">
            <h3>
              <img src={logo} className="footimg" />
              {/* <span>SPEEDEXPRESS</span> */}
            </h3>

            <p class="footer-links">
              <ul>
                <li>Home</li>
                <li>Visa</li>
                <li>Tours</li>
                <li>Teams</li>
              </ul>
            </p>

            <br />

            <p class="footer-company-name">
              SpeedExpress Tour and travel © 2020
            </p>
          </div>

          <div class="footer-center">
            <div>
              <i class="fa fa-map-marker"></i>
              <p>
                <span> Floor 2 . Room 202 </span>Deira Tower, Dubai
              </p>
            </div>
            <div>
              <i class="fa fa-map-marker"></i>
              <p>
                <span> Office No. 204 2nd Floor </span>Coop building, Abu Dhabi
              </p>
            </div>

            <div>
              <i class="fa fa-phone"></i>
              <p>
                +971 54 404 7785
                <br />
                +971 54 404 1812
              </p>
            </div>

            <div>
              <i class="fa fa-envelope"></i>
              <p>
                <a href="mailto:support@company.com">
                  info@speedexpresstravel.com
                </a>
              </p>
            </div>
          </div>

          <div class="footer-right">
            <p class="footer-company-about">
              <span>About the company</span>
              We help clients make travel plans. In addition to booking
              reservations, We assist customers in choosing their destination,
              transportation, and lodging and inform travellers of passport and
              visa requirements, rates of currency exchange, and import duties.
            </p>

            <div class="footer-icons">
              <a href="#">
                <i class="fa fa-facebook"></i>
              </a>
              <a href="#">
                <i class="fa fa-twitter"></i>
              </a>
              <a href="#">
                <i class="fa fa-linkedin"></i>
              </a>
              <a href="#">
                <i class="fa fa-github"></i>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
