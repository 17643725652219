import React from "react";
import "./More.css"

export default function More() {
  return (
    <div style={{ background: "white", padding: "20px" }}>
      <div style={{ width: "50%", margin: "auto" }}>
        <div className="row more">
          <div className="col">
            <h4>More Links</h4>
            <p>
              About SpeedExpress Tours and Travel | Travel Utilities <br />|
              International Flights | Tips For Cheap Flight | Booking <br />|
              Cheap Air Tickets | Flight Booking | FAQs | Legal | Careers |
              Contact Us| Partner
            </p>

            <h4>International tour packages</h4>
            <p>
              International tour packages | Dubai Tour Packages <br />
              |Fujairah Tour Package | Abu Dhabi Tour Package | Sharjah Tour
              Packages
            </p>
          </div>
          <div className="col">
            <h4>International Flights</h4>
            <p>
              Book Dubai To Uganda | Book Dubai To India | Book Dubai <br />
              To Nigeria | Book Dubai To Pakistan | Book Dubai To Kenya | <br />{" "}
              Book Dubai To Africa | | Book Dubai To Europe
            </p>

            <h4>International Airlines</h4>
            <p>
              Emirates | Etihad Airways | Gulf Air | Uganda Airlines <br /> |
              Kenya Airways | IndiGo | Kuwait Airways| Lufthansa Airlines |{" "}
              <br /> Oman Air | Pakistan International Airlines | Philippine
              Airlines | <br />
              SalamAir | Saudi Arabian Airlines | Singapore Airlines | SpiceJet
              Air | <br />
              France Biman | Bangladesh Airlines
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
