import React, { useState, useEffect } from "react";
import "./Tour.css";

export default function Onetour() {
  const [Page, setPage] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const contactD = document.querySelector(".fixedContact");
    // const icon = document.querySelector(".iconheader");

    const scrollTop = window.scrollY;
    scrollTop >= 20
      ? contactD.classList.add("stick")
      : contactD.classList.remove("stick");
  };
  return (
    <div>
      {/* <div className="TourPage">TOURS</div> */}

      <div className="Page">
        <div className="row uptour">
          <div className="col-9">
            <br />
            <br />

            <br />
            <br />

            <div
              className="row onetab"
              style={{ marginLeft: "5px", width: "30", color: " black" }}
              align="center"
            >
              <div
                className="col-2"
                onClick={() => setPage(0)}
                style={
                  Page == 0
                    ? { background: "#0e9a9d" }
                    : { background: "none", fontSize: " 18px" }
                }
              >
                <div className="">
                  <h3>DETAILS</h3>
                </div>
              </div>

              <div
                className="col-3"
                onClick={() => setPage(2)}
                style={
                  Page == 2 ? { background: "#0e9a9d" } : { background: "none" }
                }
              >
                <div className="">
                  <h3>LOCATION</h3>
                </div>
              </div>

              <div
                className="col-2"
                onClick={() => setPage(3)}
                style={
                  Page == 3 ? { background: "#0e9a9d" } : { background: "none" }
                }
              >
                <div className="">
                  <h3>PHOTOS</h3>
                </div>
              </div>
              <div className="col"></div>
            </div>

            {Page == 0 ? (
              <div className="onetour">
                <div className="one">
                  <hr />
                  <div className="otherlink">
                    <ul>
                      <li>Deals and Discounts</li>
                      <li>Meals included</li>
                      <li>Flights</li>
                      <li>Sports</li>
                      <li>Weather dependant</li>
                    </ul>
                  </div>
                  <div>
                    <p>
                      Divided. Fruit brought they’re they’re. Cattle him shall
                      brought winged wherein.
                      <br /> Whales itself she’d created a moveth Moved moved
                      very greater unto said earth winged, tree were.
                      <br /> Abundantly behold creepeth. <br />
                      <br />
                      Midst greater open fill image life sea greater grass.{" "}
                      <br />
                      <br />
                      Meat can’t years is life, made bearing. Under over. Upon
                      seed unto lights so which us there of. <br />
                      Whose man bearing isn’t Earth you’re replenish face
                      cattle. Isn’t said. Second don’t winged them green given
                      land, image days give.
                    </p>
                  </div>
                  <hr />
                  <div className="onebk">
                    <ul>
                      <li>
                        <h5>DEPARTURE/RETURN LOCATION</h5>
                      </li>
                      <li>Union Square, San Francisco</li>
                    </ul>
                    <hr />
                    <ul>
                      <li>
                        <h5>DEPARTURE TIME</h5>
                      </li>
                      <li>
                        Please arrive by 8:15 AM for a prompt departure at 8:30
                        AM.
                      </li>
                    </ul>
                    <hr />
                    <ul>
                      <li>
                        <h5>RETURN TIME</h5>
                      </li>
                      <li>Approximately 6:30 PM.</li>
                    </ul>
                    <hr />
                    <ul>
                      <li>
                        <h5>WEAR</h5>
                      </li>
                      <li>
                        Comfortable athletic clothing, hiking boots, hat, jacket
                        and sunscreen..
                      </li>
                    </ul>
                    <hr />
                    <ul>
                      <li>
                        <h5>INCLUDED</h5>
                      </li>
                      <li>
                        ✅ Professional instructor <br />
                        ✅Meals <br />
                        ✅1 hour of free time <br />
                        ✅Transportation in mini-coach
                      </li>
                    </ul>
                    <hr />
                    <div>
                      Fusce. Convallis morbi cubilia adipiscing. Sed aenean. Ac
                      auctor, convallis parturient commodo parturient cum id
                      eros curae; convallis. Hymenaeos non augue fusce habitasse
                      consequat vitae libero nullam ac porttitor vulputate
                      pulvinar sociis nullam. Netus porta primis. Volutpat
                      conubia libero sociis quis. Sociosqu eros mattis.
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {Page == 1 ? (
              <div className="onetour">
                <div className="one">
                  <hr />
                  <div className="otherlink">
                    <ul>
                      <li>
                        <h5>INCLUDED</h5>
                      </li>
                      <li>
                        ✅ Professional instructor <br />
                        ✅Meals <br />
                        ✅1 hour of free time <br />
                        ✅Transportation in mini-coach
                      </li>
                    </ul>
                    <hr />
                    <div>
                      Fusce. Convallis morbi cubilia adipiscing. Sed aenean. Ac
                      auctor, convallis parturient commodo parturient cum id
                      eros curae; convallis. Hymenaeos non augue fusce habitasse
                      consequat vitae libero nullam ac porttitor vulputate
                      pulvinar sociis nullam. Netus porta primis. Volutpat
                      conubia libero sociis quis. Sociosqu eros mattis.
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {Page == 2 ? (
              <div className="onetour">
                <div className="">
                  <div className="">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.178510024374!2d55.27218771543216!3d25.19720183788282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43348a67e24b%3A0xff45e502e1ceb7e2!2sBurj%20Khalifa!5e0!3m2!1sen!2sae!4v1653383900452!5m2!1sen!2sae"
                      width="100%"
                      height="850"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {Page == 3 ? (
              <div className="onetour">
                <div className=" ">
                  <div>
                    <img
                      src="http://dk-cms.boonedam.com/da/azure-blob/public/2021-06/News%20article_New%20Record%20World%27s%20Highest%20Revolving%20Doors%20Burj%20Khalifa%20_main%20pic_2880%20x%201620px.jpg"
                      width="100%"
                    />
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col">
                      <img
                        src="http://dk-cms.boonedam.com/da/azure-blob/public/2021-06/News%20article_New%20Record%20World%27s%20Highest%20Revolving%20Doors%20Burj%20Khalifa%20_main%20pic_2880%20x%201620px.jpg"
                        width="100%"
                      />
                    </div>
                    <div className="col">
                      <img
                        src="http://dk-cms.boonedam.com/da/azure-blob/public/2021-06/News%20article_New%20Record%20World%27s%20Highest%20Revolving%20Doors%20Burj%20Khalifa%20_main%20pic_2880%20x%201620px.jpg"
                        width="100%"
                      />
                    </div>
                    <div className="col">
                      <img
                        src="http://dk-cms.boonedam.com/da/azure-blob/public/2021-06/News%20article_New%20Record%20World%27s%20Highest%20Revolving%20Doors%20Burj%20Khalifa%20_main%20pic_2880%20x%201620px.jpg"
                        width="100%"
                      />
                    </div>

                    <div className="col">
                      <img
                        src="http://dk-cms.boonedam.com/da/azure-blob/public/2021-06/News%20article_New%20Record%20World%27s%20Highest%20Revolving%20Doors%20Burj%20Khalifa%20_main%20pic_2880%20x%201620px.jpg"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col" style={{ padding: "20px" }}>
            <div className="fixedContact" id="fc">
              <br />
              <br />
              <br />

              <div className="condiv">
                <h4> Burj Khalifa</h4>⭐⭐⭐⭐⭐
                <br />
                Down Town, Dubai
                <div>
                  AED 142 / night <br />
                  4.6(96) <br />
                  Speedexpresstravel.com
                </div>
                <hr />
                <button
                  className="btn btn-danger"
                  style={{ width: "100%", borderRadius: "20px" }}
                >
                  Send Inquiry
                </button>
              </div>

              <div className="condiv">
                <h4>Need help booking?</h4>
                <hr /> Call our customer services team on the number below to
                speak to one of our advisers who will help you with all of your
                holiday needs. <hr /> +971 565843743
              </div>

              <div className="condiv" style={{ display: "none" }}>
                <h4>Why Choose with us?</h4>
                <hr /> <h6>Best rates</h6> Get the best rates, or get a refund.
                No booking fees. Save money! <br />
                <br />
                <h6>Largest Selection</h6> 140,000+ hotels worldwide 130+
                airlines Over 3 million guest reviews <br /> <br />
                <h6>We’re Always Here</h6> Call or email us, anytime Get 24-hour
                support before, during, and after your trip
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="backImga" style={{ background: " #0e9a9d;" }}>
        {/* <img
          src="https://cloudfront-eu-central-1.images.arcpublishing.com/thenational/Z2FGULAF45ABBCIED2W7TS4ZJU.jpg"
          width="100%"
          // height="500px"
        /> */}
      </div>
    </div>
  );
}
