// import logo from "./logo.svg";
import Banner from "./Images/banner.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./Components/Header/Header";
import Tours from "./Components/Tours/Tours";
import Ads from "./Components/Ads/Ads";
import Vacations from "./Components/Vacations/Vacations";
import Footer from "./Components/Footer/Footer";
import Subscribe from "./Components/Subscribe/Subscribe";
import Visa from "./Components/Visa/Visa";
import { HashRouter, Switch, Route, Link } from "react-router-dom";
import Flights from "./Components/Pages/Flights/Flights";
import ScrollingHorizontally from "./Components/ScrollTest/Scroll";
import Home from "./Components/Home/Home";
import StickyFooter from "./Components/Footer/StickyFooter";
import SHeader from "./Components/Header/Header/StickyHeader";
import ToursPage from "./Components/Pages/Tours/ToursPage";
import BookTour from "./Components/Pages/Tours/BookTour";
import HotelPage from "../src/Components/Pages/Hotels/HotelPage"
import Tourscroll from "./Components/Tours/Scrolls/Tours/Tourscroll";
import Logo from "../src/Images/Speed/Banner.svg";
import Whatsapp from "../src/Images/Whatsapp.png";
import Apply from "./Components/Contact/Apply";
import icon from "../src/Images/icon.png";
import { useState } from "react";

import VisaApply from "./Components/Pages/VisaApply/Tours/Visa";
import Onetour from "./Components/Pages/Toursingle/Onetour";
import About from "./Components/About/About";
import SingleHotel from "./Components/Pages/Hotels/SigleHotel";
import Login from "./Accounts/Login/Login";

import LoginEx from "./Accounts/Login/SignTest";
import { Profile } from "./Accounts/Pages/Profile";
import ViewTours from "./Components/Pages/Tours/ToursPage";

function App() {
  const [page, setPage] = useState(0);

  const PageRoute = (a) => {
    setPage(a);
  };

  return (
    <HashRouter>
      <div className="App">
       <SHeader />
        <div style={{ background: "white", color: "black" }}>
          <div className="container">
            <div align="center">
              <br />
            </div>
            <div className="midHead">
              <ul>
                <li>
                  <Link to="/">
                    <img src={Logo} className="logoheade" />
                  </Link>
                </li>
                <li>
                  <ul className="hLink">
                    <li align="right">
                      <div align="right" style={{ width: "100%" }}>
                        <Link
                          to="/"
                          onClick={() => PageRoute(0)}
                          style={
                            page === 0
                              ? { borderBottom: "1px solid red" }
                              : { borderBottom: "none" }
                          }
                        >
                          <label>Home</label>
                        </Link>
                        <Link
                          to="/Visa"
                          onClick={() => PageRoute(1)}
                          style={
                            page === 1
                              ? { borderBottom: "1px solid red" }
                              : { borderBottom: "none" }
                          }
                        >
                          <label className="visaHover">Visa</label>
                          <div
                            style={{ color: "grey", textDecoration: "none" }}
                            align="left"
                            className="visaPop"
                          >
                            <div> Visit Visa | 1 MONTH (Outside Country)</div>
                            <div> Visit Visa | 3 MONTHS (Outside Country)</div>
                            <div> Visit Visa | 3 MONTH (Inside Country)</div>
                            <div>
                              Multiply Entry Visa | 1 MONTH (Inside Country)
                            </div>
                            <div>
                              Multiply Entry Visa | 3 MONTHS (Inside Country)
                            </div>
                          </div>
                        </Link>
                        <Link
                          onClick={() => PageRoute(2)}
                          to="/Tours"
                          style={
                            page === 2
                              ? { borderBottom: "1px solid red" }
                              : { borderBottom: "none" }
                          }
                        >
                          <label className="tourHover">Tours</label>
                          <div
                            style={{ color: "grey", textDecoration: "none" }}
                            align="left"
                            className="toursPop"
                          >
                            <div>Burj Khalifa</div>
                            <div>Al Baj</div>
                            <div>Wadi Wild</div>
                            <div>Antalatis</div>
                          </div>
                        </Link>
                        <Link
                          to="/Hotels"
                          className=""
                          onClick={() => PageRoute(3)}
                          style={
                            page === 3
                              ? { borderBottom: "1px solid red" }
                              : { borderBottom: "none" }
                          }
                        >
                          <label>Hotels</label>
                        </Link>
                        <Link
                          className="vac"
                          to="/Contact"
                          onClick={() => PageRoute(4)}
                          style={
                            page === 4
                              ? { borderBottom: "1px solid red" }
                              : { borderBottom: "none" }
                          }
                        >
                          <label>Flights</label>
                        </Link>

                        <Link
                          to="/Contact"
                          style={
                            page === 6
                              ? { borderBottom: "1px solid red" }
                              : { borderBottom: "none" }
                          }
                          onClick={() => PageRoute(6)}
                        >
                          <label>News</label>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <Header /> */}
        {/* <div className="divider"></div> */}
        <Switch>
          {/* <Route exact path="/Tours" element={<ToursPage />} /> */}
          <Route exact path="/Tours">
            <ViewTours />
          </Route>

          <Route exact path="/Visa">
            <VisaApply />
          </Route>
          {/* <Route exact path="/Tours/Book" element={<BookTour />} /> */}
          <Route exact path="/Hotels">
            <HotelPage />
          </Route>
          <Route exact path="/Scroll">
            {" "}
            <Tourscroll />
          </Route>
          <Route exact path="/Contact">
            <Apply />
          </Route>
          <Route exact path="/Apply">
            <Apply />
          </Route>
          <Route exact path="/Single">
            <Onetour />
          </Route>
          <Route exact path="/About">
            <About />
          </Route>
          <Route exact path="/OneHotel">
            <SingleHotel />
          </Route>
          <Route exact path="/Login">
            <Login />
          </Route>
          <Route exact path="/Profile">
            <Profile />
          </Route>
          <Route exact path="/Loginx">
            <LoginEx />
          </Route>

          {/* <Route exact path="/VisaAppy" element={<VisaApply />} /> */}
        </Switch>
        <Switch>
          {/* <div className="Page"> */}
          <Route exact path="/">
            <Home />
          </Route>
          {/* </div> */}
        </Switch>
        <Switch>
          {/* <div className=" "> */}
          <Route exact path="/Flights">
            <Flights />
          </Route>
          {/* </div> */}
        </Switch>
      </div>

      <div style={{}} className="subsc"></div>

      <Footer />
      <div className="floatwhatsapp">
        <a
          href="https://wa.me/+971565843743?text=I'm%20interested%20in%20Visa"
          style={{ color: "white" }}
        >
          <img src={Whatsapp} />
        </a>
      </div>
      {/* <StickyFooter/> */}
    </HashRouter>
  );
}

export default App;
