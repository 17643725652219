import React from "react";
import abu from "../Images/Countries/abudhabi.jpg";
import dubai from "../Images/Countries/Dubai.jpg";
import sharaj from "../Images/Countries/Sharjah.jpg";
import rwanda from "../Images/Countries/rwanda.jpg";

import logo from "../Images/logo.svg";
import Location from "./location";

export default function About() {
  return (
    <div>
      <div align="center" className="tabheader">
        <div className="TourPage">About Us</div>
      </div>
      <br />
      <br />
      <br />
      <div align="center">
        <iframe
          width="1000"
          height="515"
          src="https://www.youtube.com/embed/WEhUftxFzeE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <div className="container">
        <div className="location">
          {/* <ul>
            <li>
              <img src={abu} />
            </li>
            <li>
              <img src={dubai} />
            </li>
            <li>
              <img src={rwanda} />
            </li>
          </ul> */}
        </div>
        {/* <img src={logo} className="logomark" /> */}
        <div className="row abt">
          <div className="col" align="center">
            <br />
            <br />
            <h3>OUR MISSION</h3>
            <br />
            <br />
            <p style={{ fontSize: "17px" }}>
              Our first goal is to create new concepts for travel and to reach
              anywhere in the easiest, most comfortable and safe way, as we also
              strive to provide the best services and offers using the latest
              means and methods.
              <br />
              <br />
              SpeedExpress travel & tourism is always striving to develop its
              services and create a better quality of communication with its
              customers, as we have the best tools that help us provide the best
              services and provide them with a wide range of options for tourism
              programs for many distinct destinations around the world, where we
              offer them with all integrated travel and tourism services.
              <br />
              <br />
              SpeedExpress offers you a special group of offers for travel and
              excursions with high-quality, luxurious accommodation and the best
              tourist resorts, by obtaining a set of tourist visas as a tourist
              visa for Dubai. SpeedExpress can assist you with all tourist
              services, Dubai visit visa, Abu Dhabi Visa, Sharjah Visa issuance,
              as well Dubai tourism visa renewal, and we will make everything
              easy for you with all the procedures as it makes all the services
              at hand.
              <br />
              <br />
              Because we know the important role that highly qualified employees
              play in maximizing business, increasing productivity and thus
              helping SpeedExpress to succeed, we focus a lot on the training
              and development of our employees and always encourage them to
              learn and develop constantly, to enable them to upgrade their
              skills in order to meet the requirements of our clients. We have a
              professional team with more than fifteen years of experience in
              the visa field. During this period, more than one million visas
              were issued to more than seventy-five nationalities around the
              world. We pride ourselves on our high-quality service and our
              extensive and long experience that makes us pioneers in this
              field. Our team is always afraid that there will be a delay in
              authorizing its tasks or that operations will occur in a wrong
              way, so our team audits each process to avoid errors, and then it
              is submitted immediately to the competent authorities.
              <br />
              <br />
              SpeedExpress provides all tourism and travel services for those
              wishing to visit the UAE and many countries around the world,
              whether they are groups, families, or individuals, distinctly and
              suits all requirements through type and price, we always try to
              strive towards providing distinguished tourism services and
              products of high quality that contribute to the development of
              tourism in our country and meet the needs of each of our
              customers.
              <br />
              <br />
              For 15 years, we have been working in the tourism and travel
              market, organizing tourist trips and receiving delegations and
              individuals from all over the world. In the world of travel, we
              are distinguished by the special treatment of each customer
              according to his desires and preferences and at the same time we
              are keen to provide the cheapest travel price as the leading
              company, SpeedExpress, organizes a comprehensive range of travel
              packages.
              <br />
              <br />
              SpeedExpress offers you accommodation, transfers, visits, flights,
              hotels and domestic flights. We have tourist programs throughout
              the year for all tastes and financial capabilities. SpeedExpress
              continues the technology that makes travel a breeze, to provide
              unforgettable experiences and an array of choices and great
              accommodations for millions of travelers.
              <br />
              <br />
              SpeedExpress is the one that builds on the unique synergy of an
              integrated group that covers all sectors of the tourism trade. It
              offers complete solutions to the widest range of travel services
              including cultural, entertainment and conference services, where
              the company is known for its professional and dedicated workforce,
              which does not less than the highest level of services to its
              clients, as the company has maintained its position as a leading
              UAE travel company.
              <br />
              <br />
              SpeedExpress relies on its years of experience that enables it to
              easily penetrate all foreign travel markets. The company is
              equipped to assemble creative packages that perfectly meet all
              needs, as the company caters to all travel requests to any
              destination the customer wants, including business and leisure
              trips all over the world.
              <br />
              <br />
              Due to our extensive experience within the field of travel and
              tourism, the company has consolidated itself as the most credible
              company in the region, with more than 15 years of experience and
              more than a million customers, we are ready to meet the needs of
              all travelers throughout their stay, as we have the great ability
              to meet high quality solutions At every step of the traveler’s
              journey, our team of highly trained multilingual staff and
              representatives assists you with all your requests before and
              during travel. We are able to offer a wide range of different
              excursions that can appeal to all interests and tastes from
              cultural enrichment to vacations and excursions.
            </p>
          </div>
        </div>

        <div align="center">
          <hr />
          <br />
          <br />
          <h3>OUR OFFICES</h3>
        </div>

        <Location />
      </div>
    </div>
  );
}
