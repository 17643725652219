import React, { Component } from "react";
import Ads from "../Ads/Ads";
import Destinations from "../Des/Scrolls/Des/Des";
import Header from "../Header/Header";
import Hotels from "../Hotels/Hotels";
import More from "../Morelinks/More";
import ScrollingHorizontally from "../ScrollTest/Scroll";
import Subscribe from "../Subscribe/Subscribe";
import Tourscroll from "../Tours/Scrolls/Tours/Tourscroll";
import Vacations from "../Vacations/Vacations";
import Visa from "../Visa/Visa";
import Whyus from "../Whyus/Whyus";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Slideshow from "../Slider/Slider";


export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div>
      <Header />
      <Slideshow/>
      <div className="" style={{ background: "#169597" }}>
        <div className="Page">
          <br />
          <Whyus />
        </div>
      </div>
      <div className="Page">
        <div className="divider"></div>
        <br />
        <br />
        <Visa />
        <br />
        <br />
        <Hotels />

        <Ads />
        <div className="divider"></div>
      </div>

      <div className="Page">
        <Tourscroll />
        <Destinations />
      </div>
      <br />
      <br />
      <Subscribe />
      <More />

      {/* <ScrollingHorizontally /> */}
      {/* <Vacations /> */}
    </div>
  );
}

// export default Home;
