import React from "react";
import $ from "jquery";
import "./Tours.css";

import burj from "../../../../Images/HomeTours/baj.jpg";
import desert from "../../../../Images/HomeTours/desert.jpg";
import star from "../../../../Images/Icons/star.png";
import noolisland from "../../../../Tourimg/noor2.jpg";
import WildWadi from "../../../../Tourimg/wadi.jpg";
import museam from "../../../../Tourimg/mus.jpg";
import Buj from "../../../../Tourimg/buj.jpg";

import { Link } from "react-router-dom";
import "./Tours.css";
// import baj from "../../Images/Get/albaj.jpg";
// import ScrollDes from "./ScrollDes";

class Destinations extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [
        {
          City: "Turkey",
          Image:
            "https://images.unsplash.com/photo-1636213669717-7170fbff8383?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDh8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80",
        },
        {
          City: "Brazil",
          Image:
            "https://upload.travelawaits.com/ta/uploads/2021/04/aerial-view-of-rio-de-janeiroee4454.jpg",
        },
        {
          City: "New York",
          Image:
            "https://images.unsplash.com/photo-1580752300969-1ceaaa1f3039?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8bnljfGVufDB8fDB8fA%3D%3D&w=1000&q=80",
        },
        {
          City: "New Jesery",
          Image:
            "https://images.unsplash.com/photo-1636213669717-7170fbff8383?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDh8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80",
        },
        {
          City: "France",
          Image:
            "https://thumbs.dreamstime.com/b/aerial-view-paris-skyline-eiffel-tower-les-invalides-business-district-defense-as-seen-montparnasse-france-dist-126814666.jpg",
        },
      ],
    };
    this.scroll = this.scroll.bind(this);
  }
  scroll(direction) {
    let far = ($(".image-container2").width() / 2) * direction;
    let pos = $(".image-container2").scrollLeft() + far;
    $(".image-container2").animate({ scrollLeft: pos }, 1000);
  }

  render() {
    return (
      <div className="mainscrool">
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col" align="left">
            <span className="heading" style={{ color: "black" }}>
              Top<span className="red"> Destinations</span>
              <label className="redDivider"></label>
              <br />
            </span>
          </div>
          <div className="col">
            <div align="right" className="viewAll">
              View All
              <svg
                className="viewAllIco"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* <h1 className="title">Scrolling Image Slider</h1> */}
        <div className="wrapperimage">
          <a
            className="prev"
            onClick={this.scroll.bind(null, -1)}
            style={{ textDecoration: "none" }}
          >
            <span className="arrowsign">&#10094;</span>
          </a>
          <div className="image-container2">
            {this.state.data.map((item, i) => (
              <div className="image" align="center" data-aos="flip-down">
                <div>
                  <img src={item.Image} width="240px" height="350px" />
                  <div className="tourFloat" align="left">
                    <div className="tournav1">{item.City}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a
            className="next"
            onClick={this.scroll.bind(null, 1)}
            style={{ textDecoration: "none" }}
          >
            <span className="arrowsign bbc"> &#10095;</span>
          </a>
        </div>
      </div>
    );
  }
}

export default Destinations;
