import React from "react";
import "./Ads.css";
 

export default function Ads() {
  return (
    <div>
      <div className="sections">
        <div className="row">
          {/* <div className="col" align="left">
            <span className="heading" style={{ color: "black" }}>
              Book With<span className="red"> Us</span>
              <label className="redDivider"></label>
              <br />
            </span>
          </div> */}
        
        </div>
      
        <div className="Ads">
          <div className="row">
            <div className="col" align="center">
              {/* <img src={UGBAN}  width="70%" alt="" /> */}
            </div>
            {/* <div className="col adsfloat">
              <h4>Why Us</h4> <br /> To carry on the business as tourist agents
              and contractors and to facilitate traveling and to provide for
              tourists and travelers, the provision of convenience of all kinds
              in the way of through <br />1 - Tickets <br /> 2 - Visa <br /> 3 -
              Hotels <br /> 3 - Holiday Packages <br /> and other allied
              services that may be necessary for the achievement of the
              aforesaid objects.
              <br />
              <br />
              <div>
                <button
                  className="btn btn-primary search"
                  style={{ background: "#088486", border:"none" }}
                >
                  ENQUIRE NOW
                </button>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
