import axios from "axios";
// const USER_API_URL = "https://speedtest.lawrok.lol";
const USER_API_URL = "http://localhost/BK";

class UserService {
  getUsers() {
    return axios.get(`${USER_API_URL}/read.php`);
  }
  getHotels() {
    return axios.get(`${USER_API_URL}/getHotels.php`);
  }
  getBookings() {
    return axios.get(`${USER_API_URL}/rbook.php`);
  }
  createUser(user) {
    return axios.post(`${USER_API_URL}/create.php`, user);
  }
  bookhotel(obj) {
    return axios.post(`${USER_API_URL}/book.php`, obj);
  }
  updateBook(user) {
    return axios.put(`${USER_API_URL}/updatebook.php`, user);
  }
  deleteBook(Id) {
    return axios.delete(`${USER_API_URL}/deletebook.php`, {
      params: { Id: Id },
    });
  }
}
export default new UserService();
