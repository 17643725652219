import React from "react";
import "./tp.css";
import Mic from "../../../Images/Tours/miracle.jpg";
import Dst from "../../../Images/Tours/desert.jpg";
import Burj from "../../../Images/Tours/burj.jpg";
import Dcru from "../../../Images/Tours/dtours.jpg";
import Ba from "../../../Images/Tours/Baj.jpg";
import Dopl from "../../../Images/Tours/dolp.jpg";
import ml from "../../../Images/menu/menuli.png";
import mb from "../../../Images/menu/menub.png";

import { Link } from "react-router-dom";

export default function ViewTours() {
  const Tours = [
    {
      Name: "BURJ KHALIFAA",
      Image: Burj,
      Price: "140",
      Tag: "Burj_khalifa",
    },
    {
      Name: "DESERT SAFARI",
      Image: Dst,
      Price: "120",
      Tag: "Desert_Safari",
    },
    {
      Name: "MIRACLE GARDENS",
      Image: Mic,
      Price: "110",
      Tag: "Miracle_gardens",
    },
    {
      Name: "DHOW CRUISE",
      Image: Dcru,
      Price: "30",
      Tag: "Dhow",
    },
    {
      Name: "AL BURJ",
      Image: Ba,
      Price: "150",
      Tag: "Baj",
    },
    {
      Name: "DOLPHINARUIM",
      Image: Dopl,
      Price: "75",
      Tag: "Dolphin",
    },
  ];

  const TourItem = Tours.map((Tours, index) => (
    <div className="" align="center ">
      <Link
        to={"/single?" + Tours.Tag}
        style={{
          color: "black",
          padding: 0,
          margin: 0,
          textDecoration: "none",
        }}
      >
        <div className="TourDiv" data-aos="zoom-in-right">
          <img src={Tours.Image} style={{ width: "100%" }} />
          <div className="">
            <br />
            ⭐⭐⭐⭐⭐
            <h6> {Tours.Name} </h6>
            <ul>
              <li>
                {" "}
                <p>AED : {Tours.Price}</p>
              </li>
              <li>
                <button
                  className="btn btn-success"
                  style={{ fontSize: "10px" }}
                >
                  {" "}
                  BOOK NOW
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Link>
    </div>
  ));
  return (
    <div className="TP">
      <div className="TourPage">TOURS</div>
      <div>
        <div className="Page">
          <div className="row">
            <ul>
              <li>
                {/* <div className="filter">
                  <span className="filt">FILTER BY</span>
                  <br />
                  <br />

                  <div className="selectFilter">
                    <label>
                      PRICE
                      <ul>
                        <li>
                          {" "}
                          <input type="checkbox" /> 0- 100 AED
                        </li>
                        <li>
                          <input type="checkbox" /> 101 - 200 AED
                        </li>
                        <li>
                          <input type="checkbox" /> 201 - 300 AED
                        </li>
                      </ul>
                    </label>
                    <br />
                    <div>
                      <label>
                        OFFERS
                        <ul>
                          <li>
                            {" "}
                            <input type="checkbox" /> Free Cancellation
                          </li>
                          <li>
                            <input type="checkbox" /> COVID-19-responder rooms
                          </li>
                          <li>
                            <input type="checkbox" /> Special offers
                          </li>
                        </ul>
                      </label>
                    </div>

                    <div>
                      <label>
                        RATINGS
                        <ul>
                          <li>
                            {" "}
                            <input type="checkbox" /> ⭐⭐⭐
                          </li>
                          <li>
                            <input type="checkbox" />
                            ⭐⭐⭐⭐⭐
                          </li>
                          <li>
                            <input type="checkbox" />
                          </li>
                        </ul>
                      </label>
                    </div>
                  </div>
                </div> */}
              </li>
              <li>
                <div className="tourview">
                  <div className="searchTour">
                    <ul>
                      <li>
                        SORT BY :
                        <select>
                          <option>Name</option>
                          <option>Date Added</option>
                          <option>Price</option>
                        </select>
                        <select>
                          <option>Place</option>
                          <option>Dubai</option>
                          <option>Sharjah</option>
                          <option>Adu dhabi</option>
                        </select>
                        <select className="hideOpt">
                          <option>Cost</option>
                          <option>100 - 200</option>
                          <option>200 - 500</option>
                          <option>500 - 1000</option>
                        </select>
                      
                        <img
                          src={ml}
                          style={{ width: "20px", marginLeft: "10px" }}
                        />
                      </li>
                    </ul>
                    {/* <input placeholder="SEARCH" /> */}
                  </div>
                  <div className="tourview1">{TourItem}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
