import React from "react";
import $ from "jquery";
import "./Tours.css";

import burj from "../../../../Images/HomeTours/baj.jpg";
import desert from "../../../../Images/HomeTours/desert.jpg";
import star from "../../../../Images/Icons/star.png";
import noolisland from "../../../../Tourimg/noor2.jpg";
import WildWadi from "../../../../Tourimg/wadi.jpg";
import museam from "../../../../Tourimg/mus.jpg";
import Buj from "../../../../Tourimg/buj.jpg";

import { Link } from "react-router-dom";
import "./Tours.css";
// import baj from "../../Images/Get/albaj.jpg";

class Tourscroll extends React.Component {
  constructor() {
    super();
    this.scroll = this.scroll.bind(this);
  }

  scroll(direction) {
    let far = ($(".image-container").width() / 2) * direction;
    let pos = $(".image-container").scrollLeft() + far;
    $(".image-container").animate({ scrollLeft: pos }, 1000);
  }

  render() {
    return (
      <div className="mainscrool">
        <br />
        <br />
        <br />
        <div className="row">
          <div className="col" align="left">
            <span className="heading" style={{ color: "black" }}>
              Top<span className="red"> Tours</span>
              <label className="redDivider"></label>
              <br />
            </span>
          </div>
          <div className="col">
            <div align="right" className="viewAll">
              View All
              <svg
                className="viewAllIco"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-arrow-right-short"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* <h1 className="title">Scrolling Image Slider</h1> */}
        <div className="wrapperimage">
          <a
            className="prev"
            onClick={this.scroll.bind(null, -1)}
            style={{ textDecoration: "none" }}
          >
            <span className="arrowsign">&#10094;</span>
          </a>
          <div className="image-container">
            <div
              className="image"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              align="center"
            >
              <img src={burj} width="240px" />
              <div className="tourFloat" align="left">
                <div className="tournav">
                  <ul className="nametour">
                    <li>
                      <h6>Al Burj</h6>
                    </li>
                    <li className="font-weight-bold" align="right">
                      <h6 style={{ fontWeight: "bold" }}>250 AED</h6>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                        </li>
                        <li align="center">
                          <Link to="/Apply">
                            <button
                              className="btn btn-primary"
                              style={{
                                fontSize: "11px",
                                background: "#0e9a9d",
                                border: "none",
                              }}
                            >
                              BOOK NOW
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="image"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              align="center"
            >
              <img src={Buj} width="240px" />
              <div className="tourFloat" align="left">
                <div className="tournav">
                  <ul className="nametour">
                    <li>
                      <h6>Burj Khalifa</h6>
                    </li>
                    <li className="font-weight-bold" align="right">
                      <h6 style={{ fontWeight: "bold" }}>150 AED</h6>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                        </li>
                        <li align="center">
                          <Link to="/Apply">
                            <button
                              className="btn btn-primary"
                              style={{
                                fontSize: "11px",
                                background: "#0e9a9d",
                                border: "none",
                              }}
                            >
                              BOOK NOW
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="image"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              align="center"
            >
              <img src={desert} width="230px" />
              <div className="tourFloat" align="left">
                <div className="tournav">
                  <ul className="nametour">
                    <li>
                      <h6>Desert Safari</h6>
                    </li>
                    <li className="font-weight-bold" align="right">
                      <h6 style={{ fontWeight: "bold" }}>150 AED</h6>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                        </li>
                        <li align="center">
                          <Link to="/Apply">
                            <button
                              className="btn btn-primary"
                              style={{
                                fontSize: "11px",
                                background: "#0e9a9d",
                                border: "none",
                              }}
                            >
                              BOOK NOW
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="image"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              align="center"
            >
              <img src={museam} width="240px" />
              <div className="tourFloat" align="left">
                <div className="tournav">
                  <ul className="nametour">
                    <li>
                      <h6>Future Museum</h6>
                    </li>
                    <li className="font-weight-bold" align="right">
                      <h6 style={{ fontWeight: "bold" }}>600 AED</h6>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                        </li>
                        <li align="center">
                          <Link to="/Apply">
                            <button
                              className="btn btn-primary"
                              style={{
                                fontSize: "11px",
                                background: "#0e9a9d",
                                border: "none",
                              }}
                            >
                              BOOK NOW
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="image"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              align="center"
            >
              <img src={WildWadi} width="240px" />
              <div className="tourFloat" align="left">
                <div className="tournav">
                  <ul className="nametour">
                    <li>
                      <h6>Wild Wadi</h6>
                    </li>
                    <li className="font-weight-bold" align="right">
                      <h6 style={{ fontWeight: "bold" }}>600 AED</h6>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                          <img src={star} width="30px" />
                        </li>
                        <li align="center">
                          <Link to="/Apply">
                            <button
                              className="btn btn-primary"
                              style={{
                                fontSize: "11px",
                                background: "#0e9a9d",
                                border: "none",
                              }}
                            >
                              BOOK NOW
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a
            className="next"
            onClick={this.scroll.bind(null, 1)}
            style={{ textDecoration: "none" }}
          >
            <span className="arrowsign bbc"> &#10095;</span>
          </a>
        </div>
      </div>
    );
  }
}

export default Tourscroll;
