import React, { Component } from "react";
import "./Profile.css";
import Dp from "../Profile/Pic.jpg";
import { Redirect } from "react-router-dom";
import Services from "../../Services/Api";
import { Hotels } from "../../Data/Data";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HotelBook: [],
      UserArray: [],
      UserId: 1,
      loadingHotel: true,
      Email: "",
      EditId: 0,
    };
    this.edit = this.edit.bind(this);
    this.Dedit = this.Dedit.bind(this);
    this.updateBook = this.updateBook.bind(this);
  }
  componentDidMount() {
     
    setInterval(() => {
      Services.getUsers().then((res) => {
        console.log("UserArray", res.data);
        this.setState({ UserArray: res.data });
        for (let a = 0; a < this.state.UserArray.length; a++) {
          if (
            this.state.UserArray[a].username == localStorage.getItem("Login")
          ) {
            console.log(
              "Found",
              this.state.UserArray[this.state.UserId].email_id
            );
            this.setState({
              Email: this.state.UserArray[a].email_id,
            });
          }
        }
      });
      Services.getBookings().then((res) => {
        console.log("res.datav", res.data);
        this.setState({ HotelBook: res.data });
        console.log(this.state.HotelBook);
        this.setState({ loadingHotel: false });
        console.log("EditId", this.state.EditId);
        // console.log("Idddfff", this.state.HotelBook[1].Id);
      });
    }, 1000);
  }
  deleteUser(Id) {
    console.log("Id", Id);
    Services.deleteBook(Id).then((res) => {});
  }
  updateBook(Id) {
    const data = {
      Id: Id,
      Checkin: "GEORGE",
    };
    console.log("uPDATE cALLED", Id);
    Services.updateBook(data).then((res) => {
      console.log("res, res", res.data);
    });
  }
  edit(Id) {
    console.log("REdoit", Id);
    this.setState({ EditId: Id });
  }
  Dedit(Id) {
    console.log("REdoit", Id);
    this.setState({ EditId: 0 });
  }
  render() {
    if (!localStorage.getItem("Login")) {
      return <Redirect to="/Login" />;
    }
    return (
      <div>
        <div className="profile">
          <div className="row">
            <div className="col-4" align="center">
              <div className="profilePic">
                <img src={Dp} width="100%" style={{ borderRadius: "50%" }} />
              </div>
              <h3>Mr {localStorage.getItem("Login")}</h3>

              <div className="EP">
                <ul>
                  <li onClick={this.onclickk}>My Profile</li>

                  <li align="center">
                    {" "}
                    <label className="Edit">
                      Edit{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                    </label>
                  </li>
                </ul>
                <div className="PR" align="center">
                  <p>
                    SpeedID : <span className="ProData">C001</span>
                  </p>
                  <p>
                    Email : <span className="ProData">{this.state.Email}</span>
                  </p>
                  <p>
                    DOB : <span className="ProData">03/10/1995</span>
                  </p>
                  <p>
                    Nationality : <span className="ProData">Uganda</span>
                  </p>
                </div>
                <div>
                  <div className="row">
                    <div className="col-6" align="center">
                      <div className="addPr">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          fill="currentColor"
                          class="bi bi-filetype-doc"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"
                          />
                        </svg>
                        <br />

                        <p>ADD PROFILE</p>
                      </div>
                    </div>
                    <div className="col-6" align="center">
                      <div className="addPr">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          fill="currentColor"
                          class="bi bi-person-bounding-box"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z" />
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        </svg>
                        <br />

                        <p>ADD PASSPORT</p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col BookingData">
              <div className="row">
                <div className="col">
                  <p>My Bookings</p>
                </div>
                <div className="col" align="right" style={{ fontSize: "10px" }}>
                  Service Mode :
                  <label style={{ paddingLeft: "30px" }}>
                    Hotel{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>{" "}
                  </label>
                  | Sort By :{" "}
                  <label style={{ paddingLeft: "30px" }}>
                    Date
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </label>
                </div>
              </div>
              <div style={{ borderBottom: "1px solid grey", padding: "10px" }}>
                <div className="his" style={{ width: "40%" }}>
                  <div className="row">
                    <div
                      className="col"
                      style={{ fontWeight: "bold", color: "darkblue" }}
                    >
                      All Booking
                    </div>
                    <div className="col">Upcoming </div>
                    <div className="col">Past</div>
                  </div>
                </div>
              </div>
              <br />

              {this.state.loadingHotel ? (
                <Skeleton count={5} height="60px" baseColor="#dbf9f9" />
              ) : (
                <div>
                  {this.state.HotelBook.map((Hotel, index) => (
                    <div>
                      {this.state.EditId != 0 ? (
                        <div
                          className="update"
                          style={
                            this.state.EditId == Hotel.HotelId
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div>
                            <h3>UPDATE BOOKINGS</h3>
                            <br />

                            <div>
                              <div>
                                <div>
                                  <img
                                    src={Hotels[this.state.EditId].images1}
                                    height="180px"
                                    width="300px"
                                  />
                                  <br />
                                  <br />
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Hotel : {Hotel.Hotelname}
                                  </p>
                                </div>
                                <div>
                                  <h6>CheckIn Date</h6>
                                  <p>Booked : {Hotel.Checkin}</p>
                                  <input type="date" />
                                </div>
                                <br />
                                <div>
                                  <h6>Select Night</h6>
                                  <select>
                                    <option>1 Night</option>
                                    <option>2 Nights</option>
                                  </select>
                                </div>
                                <br />
                              </div>
                            </div>

                            <hr />
                            <div>
                              Price : <span>{Hotel.Hotelprice}</span> AED
                            </div>
                            <div className="vbtn">
                              <button
                                className="btn btn-warning"
                                onClick={() => this.updateBook(Hotel.Id)}
                              >
                                REBOOK
                              </button>
                              <button className="btn btn-danger">
                                CANCEL BOOKING
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => this.Dedit(index)}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {Hotel.Username == localStorage.getItem("Login") ? (
                        <div className="Xb" style={{ padding: "10px" }}>
                          <div align="center" style={{ fontWeight: "bold" }}>
                            {Hotel.Hotelname} Hotel
                          </div>
                          <ul>
                            {/* <li className="hbox">
                              <ul>
                                <li style={{ fontSize: "10px" }} align="center">
                                  <img
                                    src={Hotels[Hotel.HotelId].images1}
                                    height="70px"
                                    width="120px"
                                  />
                                  <br />
                                </li>
                              </ul>
                            </li> */}
                            <li
                              style={{
                                paddingTop: "10px",
                                paddingLeft: "40px",
                                fontSize: "10px",
                              }}
                            >
                              {Hotel.Checkin} <br />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                                className="flobk"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>{" "}
                              {Hotels[Hotel.HotelId].location}
                              <br />
                              <a href="#" style={{ color: "lightblue" }}>
                                View Map
                              </a>
                            </li>
                            <li
                              style={{ paddingTop: "10px", fontSize: "10px" }}
                            >
                              <br />
                              PEOPLE
                              <br />
                              <span style={{ color: "grey", fontSize: "10px" }}>
                                2 Adult , 0 Kids
                              </span>
                            </li>
                            <li style={{ color: "grey", fontSize: "10px" }}>
                              <br />
                              Check In
                              <br />
                              <span style={{fontSize:'16px', color:"green"}}>{Hotel.Checkin}</span>
                              <br />
                            </li>

                            <li style={{ fontSize: "12px" }}>
                              <br />
                              Price : <br /> {Hotel.Hotelprice} AED
                            </li>
                            <li>
                              <br />
                              <label className="modi">
                                <svg
                                  onClick={() => this.edit(Hotel.HotelId)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>

                                <svg
                                  onClick={() => this.deleteUser(Hotel.Id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                </svg>
                                {/* </button> */}
                                <br />
                                <span style={{ color: "red" }}>Confirmed</span>
                              </label>
                            </li>
                            <li>
                              <br />
                              <label
                                style={{ color: "blue", paddingLeft: "12px" }}
                              >
                                Print
                              </label>
                              <label
                                style={{ color: "blue", paddingLeft: "12px" }}
                              >
                                Mail
                              </label>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
