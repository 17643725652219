import React, { Component } from "react";
import "./tp.css";
import { Link } from "react-router-dom";
import { Hotels as HOTELIM } from "../../../Data/Data";
import ml from "../../../Images/menu/menuli.png";
import mb from "../../../Images/menu/menub.png";
import Services from "../../../Services/Api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export class HotelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HotelList: [],
      price: false,
      Select: "Select",
      loadHotel: false,
    };
    this.setPrice = this.setPrice.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
  }
  componentDidMount() {
    // setInterval(() => {
    Services.getHotels().then((res) => {
      console.log(res.data);
      this.setState({ HotelList: res.data, loadHotel: true });
    });
    // }, 1000);
  }
  setPrice() {
    console.log("Clicked Price");
    this.setState({ price: true });
    if (this.state.price == true) {
      this.setState({ price: false });
    }
  }
  onChangeSelect(e) {
    this.setState({
      Select: e.target.value,
    });

    console.log("Clicked Price");
    this.setState({ price: true });
  }

  sort() {
    const compare = (a, b) => {
      const aPrice = Number(a.HP);
      const bPrice = Number(b.HP);
      return aPrice - bPrice;
    };
  }

  render() {
    return (
      <div className="TP">
        <div className="TourPage">
          HOTELS
          <br />
          <div className="searchHotel">
            <ul>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-geo-alt"
                  viewBox="0 0 16 16"
                  className="floloc"
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                <input
                  placeholder="Location"
                  style={{
                    width: "97%",
                    paddingLeft: "25px",
                    borderRadius: "8px",
                  }}
                />
              </li>
              <li>
                <ul>
                  <li>
                    <input
                      type="date"
                      name="Select Date"
                      style={{ marginRight: "10px" }}
                    />
                  </li>

                  <li>
                    <select>
                      <option name="">1 Adult</option>
                      <option name="">2 Adults</option>
                    </select>
                  </li>
                </ul>
              </li>
              <li className="btnx">
                <button className="btn btn-success"> SEARCH </button>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="Page">
            <div className="row">
              <ul className="hotelSelct">
                <li className="hotelSelct11">
                  <div
                    className="filter"
                    style={{ borderRadius: "10px", fontSize: "12px" }}
                  >
                    <span className="filt">FILTER BY</span>
                    <br />
                    <br />
                    <div className="selectFilter">
                      <label>
                        PRICE
                        <ul>
                          <li>
                            <input type="checkbox" checked /> 0- 100 AED
                          </li>
                          <li>
                            <input type="checkbox" checked /> 101 - 200 AED
                          </li>
                          <li>
                            <input type="checkbox" checked /> 201 - 300 AED
                          </li>
                        </ul>
                      </label>
                      <br />
                      <div>
                        <label>
                          LOCATION
                          <ul>
                            <li>
                              <input type="checkbox" checked /> Dubai
                            </li>
                            <li>
                              <input type="checkbox" checked /> Sharjah
                            </li>
                            <li>
                              <input type="checkbox" checked /> Abu Dhabi
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div>
                        <label>
                          OFFERS
                          <ul>
                            <li>
                              <input type="checkbox" checked /> Free
                              Cancellation
                            </li>
                            <li>
                              <input type="checkbox" checked />{" "}
                              COVID-19-responder rooms
                            </li>
                            <li>
                              <input type="checkbox" checked /> Special offers
                            </li>
                          </ul>
                        </label>
                      </div>
                      <div>
                        <label>
                          RATINGS
                          <ul>
                            <li>
                              <input type="checkbox" checked /> ⭐⭐⭐
                            </li>
                            <li>
                              <input type="checkbox" checked />
                              ⭐⭐⭐⭐
                            </li>
                            <li>
                              <input type="checkbox" checked />
                              ⭐⭐⭐⭐⭐
                            </li>
                          </ul>
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="hotelSelct11">
                  <br />
                  <br />
                  <br />
                  <div className="tourview">
                    <div className=" ">
                      <div className="tours">
                        <div>
                          {this.state.loadHotel == false ? (
                            <Skeleton
                              count={3}
                              height="80px"
                              width="98%"
                              baseColor="#cde9e9"
                            />
                          ) : (
                            <div>
                              <div
                                className="searchTour datahotel"
                                style={{ width: "95%", fontSize: "12px" }}
                              >
                                <ul
                                  style={{ width: "90%", background: "green" }}
                                >
                                  <li style={{ fontSize: "12px", flex: 1 }}>
                                    SORT BY :
                                    <select
                                      onChange={this.onChangeSelect}
                                      value={this.state.Select}
                                    >
                                      <option>Name</option>
                                      <option>Date Added</option>
                                      <option>Price</option>
                                    </select>
                                    <select>
                                      <option>Place</option>
                                      <option>Dubai</option>
                                      <option>Sharjah</option>
                                      <option>Adu dhabi</option>
                                    </select>
                                    <select className="hideOp">
                                      <option>Cost</option>
                                      <option>100 - 200</option>
                                      <option>200 - 500</option>
                                      <option>500 - 1000</option>
                                    </select>
                                    <img
                                      src={mb}
                                      style={{
                                        width: "20px",
                                        marginLeft: "20px",
                                        borderRadius: "0",
                                      }}
                                    />
                                  </li>
                                </ul>
                              </div>
                              {this.state.HotelList.sort(
                                this.state.price
                                  ? (a, b) =>
                                      parseFloat(b.HP) - parseFloat(a.HP)
                                  : (a, b) => b.HN
                              ).map((Hotels, index) => (
                                <div
                                  style={{ margin: " " }}
                                  className="hoteltab"
                                >
                                  {" "}
                                  <div className=" ">
                                    <ul>
                                      <li align="">
                                        <img src={HOTELIM[Hotels.id].images1} />
                                        <div className="hiP">
                                          <br />
                                          <br />
                                          <br />
                                          <h6>
                                            PRICE : {Hotels.HP}{" "}
                                            <span className="TotP">AED</span>
                                          </h6>

                                          <h5 className="star">⭐⭐⭐</h5>
                                          <Link to={"/onehotel?" + Hotels.id}>
                                            <button className="btn btn-primary  bookTourBnt">
                                              VIEW
                                            </button>
                                          </Link>
                                        </div>
                                      </li>
                                      <li align="left">
                                        <h6 style={{ fontWeight: "bold" }}>
                                          {" "}
                                          {Hotels.HN}
                                        </h6>
                                        <span>Sanitizing measures </span>
                                        <hr />
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-mask"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M6.225 1.227A7.5 7.5 0 0 1 10.5 8a7.5 7.5 0 0 1-4.275 6.773 7 7 0 1 0 0-13.546zM4.187.966a8 8 0 1 1 7.627 14.069A8 8 0 0 1 4.186.964z" />
                                          </svg>
                                          Travel Sustainable property
                                        </span>
                                        <br />
                                        <p
                                          style={{
                                            color: "green",
                                            fontSize: "10px",
                                          }}
                                          className="hp"
                                        >
                                          <br /> FREE cancellation
                                          <br /> • No prepayment needed You can
                                          cancel later, so lock in this great
                                          price today!
                                        </p>
                                        <p style={{ color: "red" }}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-geo-alt"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                          </svg>
                                          {Hotels.HL}
                                        </p>
                                      </li>
                                      <li align="center" lca>
                                        <br />
                                        <br />
                                        <br />
                                        <h6>
                                          PRICE : {Hotels.HP}{" "}
                                          <span className="TotP">AED</span>
                                        </h6>

                                        <h5 className="star">⭐⭐⭐</h5>
                                        <Link to={"/onehotel?" + Hotels.id}>
                                          <button className="btn btn-primary bookTourBnt">
                                            VIEW
                                          </button>
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HotelPage;
