import React from "react";
import "../Account.css";
import { Component } from "react";
import axios from "axios";
// import { Navigate } from "react-router-dom";
import Services from "../../../src/Services/Api";
import { Redirect } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeFName = this.onChangeFName.bind(this);
    this.onChangeLName = this.onChangeLName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordconform = this.onChangePasswordconform.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.state = {
      page: 0,
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      passwordConform: "",
    };
  }
 onChangeName(e) {
    this.setState({
      username: e.target.value,
    });
  }
  onChangeFName(e) {
    this.setState({
      first_name: e.target.value,
    });
  }
  onChangeLName(e) {
    this.setState({
      last_name: e.target.value,
    });
  }
  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
 onChangePhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }
 onChangePasswordconform(e) {
    this.setState({
      passwordConform: e.target.value,
    });
  }
 onSubmit(e) {
    e.preventDefault();
    let obj = {
      email_id: this.state.email,
      Pw: this.state.password,
    };

    let array;

    Services.getUsers().then((res) => {
      this.setState({ client: res.data });
      // console.log("asd", res.data[0].email_id);
      console.log("Name", this.state.email);
      let CheckUser = false;
      let get = false;
      for (let a = 0; a < res.data.length; a++) {
        if (res.data[a].email_id == this.state.email) {
          localStorage.setItem("Login", res.data[a].username);
          console.log("User Valoe");
          window.location.reload();
          get = true;
        }
      }
      if (get == false) {
        window.alert("Invalid User or Password");
      }
      if (get == true) {
        <Redirect from="/" to="/home" />;
      }
    });
    this.props.history.push("/View");
  }
  onAdd(e) {
    console.log("cleckec");
    e.preventDefault();
    let obj = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      username: this.state.username,
      email_id: this.state.email,
      password: this.state.password,
    };

    Services.createUser(obj).then((res) => {
      this.setState({ client: [] });
      Services.getUsers().then((res) => {
        this.setState({ client: res.data });
        console.log("clients", res.data);
        window.location.reload();
        <Redirect from="/" to="/Profile" />;
      });
    });
  }
  Data() {
    const signUpButton = document.getElementById("signUp");
    const signInButton = document.getElementById("signIn");
    const container = document.getElementById("container");

    signUpButton.addEventListener("click", () => {
      container.classList.add("right-panel-active");
    });

    signInButton.addEventListener("click", () => {
      container.classList.remove("right-panel-active");
    });
  }
  render() {
    if (localStorage.getItem("Login")) {
      return <Redirect from="/" to="/Profile" />;
    }
    return (
      <div classNameName="account">
        {/* <div classNameName="row" style={{ height: "100vh" }}> */}
        <div className="containerLog" id="container">
          <div className="form-container sign-up-container">
            <div className="form">
              <br />
              <br />
              <h1>Create Account</h1>
              <div className="social-container">
                <a href="#" className="social">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="social">
                  <i className="fab fa-google-plus-g"></i>
                </a>
                <a href="#" className="social">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <span>or use your email for registration</span>
              <input
                type="text"
                placeholder="First Name"
                value={this.state.name}
                onChange={this.onChangeFName}
              />
              <input
                type="text"
                placeholder="Last Name"
                value={this.state.name}
                onChange={this.onChangeLName}
              />
              <input
                type="text"
                placeholder="User Name"
                value={this.state.name}
                onChange={this.onChangeName}
              />
              <input
                type="text"
                placeholder="Phone"
                value={this.state.phone}
                onChange={this.onChangePhone}
              />
              <input
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
              <input
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.onChangePassword}
              />
              <input
                type="password"
                placeholder="Confirm Password"
                value={this.state.passwordConform}
                onChange={this.onChangePasswordconform}
              />
              <button onClick={this.onAdd}>Sign Up</button>
            </div>
          </div>
          <div className="form-container sign-in-container">
            <div className="form">
              <br />
              <br />
              <br />
              <h1>Sign in</h1>
              <div className="social-container">
                <a href="#" className="social">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="social">
                  <i className="fab fa-google-plus-g"></i>
                </a>
                <a href="#" className="social">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <span>to your account</span>
              <input
                type="email"
                placeholder="Username"
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
              <input
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.onChangePassword}
              />

              <a
                href="#"
                style={{ color: "lightblue", textDecoration: "none" }}
              >
                Forgot your password?
              </a>
              <br />
              <button onClick={this.onSubmit}>Sign In</button>
            </div>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button
                  className="ghost"
                  id="signIn"
                  onClick={() => this.Data()}
                >
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <button
                  className="ghost"
                  id="signUp"
                  onClick={() => this.Data()}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
