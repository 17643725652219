import React from "react";
import "./About.css";
import { AddressMap } from "./Map";

export default function Location() {
  return (
    <div className="container">
      {/* <hr /> */}
      <ul className="loc" align="">
        <li>
          <h2>DUBAI</h2>

          <div>
            <AddressMap />
          </div>
          <div>
            <i class="fa fa-map-marker"></i>
            <span> Floor 2 . Room 202 </span>Deira Tower, Dubai <br />
            <i class="fa fa-phone"></i>
            +971 24 442 564
          </div>
        </li>
        <li>
          <h2>ADU DHABI</h2>

          <div>
            <AddressMap />
          </div>
          <div>
            <i class="fa fa-map-marker"></i>
            <span> Floor 2 . Room 202 </span>Deira Tower, Dubai <br />
            <i class="fa fa-phone"></i>
            +971 24 442 564
          </div>
        </li>
        <li>
          <h2>RWANDA</h2>

          <div>
            <AddressMap />
          </div>
          <div>
            <i class="fa fa-map-marker"></i>
            <span> Floor 2 . Room 202 </span>Deira Tower, Dubai <br />
            <i class="fa fa-phone"></i>
            +971 24 442 564
          </div>
        </li>
        <li>
          <h2>UGANDA</h2>

          <div>
            <AddressMap />
          </div>
          <div>
            <i class="fa fa-map-marker"></i>
            <span> Floor 2 . Room 202 </span>Deira Tower, Dubai <br />
            <i class="fa fa-phone"></i>
            +971 24 442 564
          </div>
        </li>
      </ul>
      <hr />
    </div>
  );
}
